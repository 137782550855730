import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CarouselHOC from "../../03_organisms/CarouselHOC";
import Image from 'next/image';
import {pickImageStyle} from '../../../utils/transforms';

const slickSettThumb = {
  asNavFor: '.slider-for',
  dots: false,
  arrows: false,
  swipeToSlide: true,
  slidesToScroll: 0,
  slidesToShow: 5,
  infinite: true,
  focusOnSelect: true,
  centerPadding: '10px',
  autoplay: true,
};
const slickSettContent = {
  dots: false,
  arrows: false,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: true,
};

const useStyles = makeStyles((theme) => ({
      tenantName: {
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
          position: 'absolute',
          bottom: '55px',
          right: '200px',
        },
      },
      reviewWrap: {
        position: 'relative',
        "& .slick-list": {
          overflow: 'visible',
        },
        "& .slick-thumb .slick-active img": {
          border: 'solid 4px ' + theme.palette.success.main,
        },
      },
      reviewDesc: {
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 1.2,
        padding: '0 3rem',
        fontWeight: 300,
        [theme.breakpoints.up('md')]: {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '65%',
          width: 895,
        },
      },
      mainImage: {
        width: '190px !important',
        position: 'relative',
        zIndex: '100',
        height: '190px !important',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '90px !important',
          maxHeight: '90px !important',
          margin: '2rem auto',
          display: 'block',
          bottom: '-2rem',
        },
        [theme.breakpoints.up('md')]: {
          position: 'absolute',
          right: '0',
          top: '50%',

          transform: 'translateY(-50%)',
        },
        "& img": {
          width: '190px !important',
          height: '190px !important',
          borderRadius: '100%',
          zIndex: 20,
          objectFit: 'cover',
          objectPosition: 'center',
        },
      },
    })
);

// To override/merge styles in CarouselHOC:
const useSliderStyles = makeStyles((theme) => ({
  galleryImage: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minHeight: 485,
    },
  },
  thumbnailSliderWrap: {
    maxWidth: 400,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      marginTop: '-60px',
    },
    "& .slick-slide:hover": {
      cursor: 'pointer',
      "& img": {
        border: 'solid 4px ' + theme.palette.warning.main + '!important',
      },
    },
    "& .slick-current img": {
      border: 'solid 4px ' + theme.palette.success.main + '!important',
    },
    "& .slick-track": {},
    "& .slick-thumb .slick-active img": {
      border: 'solid 4px ' + theme.palette.success.main + '!important',
    },
    //   },
  },
  slickThumbnail: {
    width: '68px !important',
    height: '68px !important',
    minHeight: '68px !important',
    minWidth: '68px !important',
    borderRadius: '100%',
    margin: '0 .5rem',
    border: 'solid 4px white !important',
    // [theme.breakpoints.up('md')]: {
    //   transform: 'translateY(-5rem)',
    // },
  },
}));

export function Testimonial(props) {
  const classes = useStyles(props);
  const HOCstyles = useSliderStyles(props);
  let {
    title,
    body,
    imgAlt,
    imgTitle,
    imgWidth,
    imgHeight,
    imageOrig,
    image_styles
  } = props.node;

  const getImageStyle = pickImageStyle(
      {
        imgAlt: imgAlt,
        imgTitle: imgTitle,
        imgWidth,
        imgHeight,
        imageOrig,
        image_styles,
        title,
      },
      'orig_optimized',
  );
  ({imgAlt, imgTitle, imgWidth, imgHeight, imageOrig} = getImageStyle);

  return (
      <Container className={`${classes.reviewWrap} testimonial-single`}>
        <Grid container>
          <Grid item xs={12}>
            <div className={HOCstyles.galleryImage}>
              <Typography className={classes.reviewDesc}
                          dangerouslySetInnerHTML={{__html: body}}></Typography>
              <Box className={classes.mainImage} position={`relative`}>
                {imageOrig &&
                <Image width="0" height="0" style={{layout: 'fill'}}
                       sizes="100vw" fill={true}
                       alt={imgAlt} title={imgTitle} src={imageOrig}/>
                }
              </Box>
              <Typography className={classes.tenantName}>{title}</Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
  );
}

export default function TestimonialCarousel(props) {
  let {slides, children, contentThumbs, showThumb} = props;
  const HOCstyles = useSliderStyles(props);
  showThumb = showThumb || true;
  return (
      <div className={`testimonials-carousel`}>
        <CarouselHOC classes={HOCstyles} slides={slides} sponsors
                     testimonialBackground
                     contentThumbs={contentThumbs}
                     showThumb={showThumb} slickSett={slickSettContent}
                     slickNavSett={slickSettThumb}
        >
          {children}
        </CarouselHOC>
      </div>
  );
}
